export const levels = [
  { title: 'Trainee', cost: '20 b-coin' },
  { title: 'Junior', cost: '30 b-coin' },
  { title: 'Middle', cost: '40 b-coin' },
  { title: 'Senior', cost: '60 b-coin' },
  { title: 'Lead', cost: '70 b-coin' },
  { title: 'Architect', cost: '80 b-coin' },
];

export const certifications = [
  {
    cost: '5 b-coins',
    description:
      'for successful profile certifications by Microsoft Certification, for example, Developing ASP.NET MVC Web Applications.',
  },
  {
    cost: '10 b-coins',
    description:
      'for successful profile certifications by Microsoft Certification, for example, Developing ASP.NET MVC Web Applications.',
  },
  {
    cost: '25 b-coins',
    description:
      'for successful certifications by ISTQB and Scrum Master Certification.',
  },
  {
    cost: '35 b-coins',
    description:
      'for passing top certifications: Azure, AWS Certified, GCP professional, PMP, etc.',
  },
  {
    note: '* The list of certifications is not complete, so if you pass a certification that is not on the list, write to Marvin.',
  },
];

export const frequentQuestions = [
  {
    id: 'question-0',
    question: 'How do I get b-coins?',
  },
  {
    id: 'question-1',
    question:
      'How many b-coins will I get for a recommendation of a candidate?',
  },
  {
    id: 'question-2',
    question: 'How many company social media tags can I make per month?',
  },
  {
    id: 'question-3',
    question:
      'How often can I complete the task "Conduct Webinars, Courses, or Internal KSS"?',
  },
  {
    id: 'question-4',
    question: 'When is the exchange deadline?',
  },
  {
    id: 'question-5',
    question: 'Can b-coin be presented to someone else?',
  },
  {
    id: 'question-6',
    question: 'How many feedbacks can I leave about the company?',
  },
  {
    id: 'question-7',
    question: 'What certifications can I get b-coin for?',
  },
  // {
  //   id: 'question-8',
  //   question: 'How long should I wait for my order?',
  // },
];
