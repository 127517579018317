import classNames from 'classnames';
import styles from '../../ProductForm.module.scss';
import { FC } from 'react';
import { PropertiesProps } from './types';

const Properties: FC<PropertiesProps> = ({
  properties,
  formData,
  handlePropertyChange,
}) => {
  return (
    <>
      {Object.keys(properties).reduce((acc, key) => {
        const values = properties[key];

        if (values?.length) {
          acc.push(
            <div className={styles.propertySelector} key={key}>
              <div className={styles.label}>Select {key.toLowerCase()}:</div>
              <div className={styles.options}>
                {values.map((value: string) => (
                  <button
                    type='button'
                    key={value}
                    className={classNames(styles.property, {
                      [styles.active]: formData[key.toLowerCase()] === value,
                    })}
                    onClick={() => handlePropertyChange(key, value)}
                  >
                    {value}
                  </button>
                ))}
              </div>
            </div>,
          );
        }

        return acc;
      }, [] as JSX.Element[])}
    </>
  );
};

export default Properties;
