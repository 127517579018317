import React, { FC } from 'react';
import styles from './ProductForm.module.scss';
import { ReactComponent as NovaPostIcon } from '../../../../../../icons/NovaPostIcon.svg';
import { FormProps } from './types';
import SizeTooltip from './components/SizeTooltip/SizeTooltip';
import { useProductForm } from './useProductForm';
import Properties from './components/Properties/Properties';
import ButtonBlock from './components/ButtonBlock/ButtonBlock';
import SizeCharts from './components/SizeCharts/SizeCharts';
import classNames from 'classnames';

const Form: FC<FormProps> = ({
  handlePrev,
  displaySuccessfull,
  item,
  setIsLoading,
}) => {
  const {
    confirmCheckoutHandler,
    formData,
    formErrors,
    handleIconClick,
    handleInputChange,
    handlePropertyChange,
    hasSizeProperty,
    properties,
    setTooltipVisible,
    sizeChartRef,
    sizeTable,
    tooltipVisible,
  } = useProductForm({ displaySuccessfull, item, setIsLoading });

  return (
    <div className={styles.checkoutForm}>
      <h2>Checkout</h2>

      <form>
        <input
          type='text'
          name='fullName'
          placeholder="Recipient's full name"
          value={formData.fullName}
          onChange={handleInputChange}
          className={classNames(styles.userName, {
            [styles.error]: formErrors.fullName,
          })}
          required
          disabled // temporary disabled
        />

        <input
          type='tel'
          name='phoneNumber'
          placeholder='Phone number'
          value={formData.phoneNumber}
          onChange={handleInputChange}
          className={formErrors.phoneNumber ? styles.error : ''}
          required
        />

        <div className={styles.inputWithIcon}>
          <input
            type='text'
            name='deliveryAddress'
            placeholder='Delivery address'
            value={formData.deliveryAddress}
            onChange={handleInputChange}
            className={formErrors.deliveryAddress ? styles.error : ''}
            required
          />
          <NovaPostIcon className={styles.icon} onClick={handleIconClick} />
        </div>

        {hasSizeProperty && (
          <SizeCharts {...{ sizeChartRef, setTooltipVisible }} />
        )}

        <SizeTooltip visible={tooltipVisible} targetRef={sizeChartRef}>
          <img src={sizeTable} alt='Size Chart' />
        </SizeTooltip>

        {properties && (
          <Properties
            properties={properties}
            formData={formData}
            handlePropertyChange={handlePropertyChange}
          />
        )}

        <ButtonBlock {...{ handlePrev, confirmCheckoutHandler }} />
      </form>
    </div>
  );
};

export default Form;
