import { useState, useEffect } from 'react';
import { BCoinRewardDto } from '../../../../api/api-types';
import {
  getBCoinRewards,
  getEmployeeBCoinBalance,
} from '../../../../api/bcoin';
import { UseBCoinWhatToGetReturn } from './types';

export const useBCoinWhatToGet = (): UseBCoinWhatToGetReturn => {
  const [bcoinRewards, setBcoinRewards] = useState<BCoinRewardDto[]>([]);
  const [employeeBCoinBalance, setEmployeeBCoinBalance] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const [rewardsData, balanceData] = await Promise.all([
          getBCoinRewards(),
          getEmployeeBCoinBalance(),
        ]);

        setBcoinRewards(rewardsData ?? []);
        setEmployeeBCoinBalance(balanceData);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const cleanString = (str: string) =>
    str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

  const filteredRewards = bcoinRewards.filter(
    (reward) =>
      reward.name &&
      cleanString(reward.name).includes(cleanString(searchQuery)),
  );

  return {
    employeeBCoinBalance,
    filteredRewards,
    handleSearchChange,
    isLoading,
    setIsLoading,
    searchQuery,
  };
};
