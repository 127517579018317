import { FC } from 'react';
import Button from '../../../../../components/Button/Button';
import { BCoinItemsCharacteristics } from '../../BCoinItemsCharacteristics';
import ItemPrice from './ItemPrice';
import { BCoinModalDataProps } from '../types';
import classNames from 'classnames';

const BCoinModalData: FC<BCoinModalDataProps> = ({
  characteristics,
  props,
  handleNext,
  disableConfirmButton,
}) => {
  return (
    <div className='bcoin-modal-data'>
      <div className='bcoin-modal-info'>
        <div className='bcoin-modal-header'>
          <div className='bcoin-modal-title'>{props.item.name}</div>
          <div className='bcoin-modal-description'>
            {props.item.description}
          </div>
        </div>

        {
          // TODO: contact backend to define different types of rewards and remove this hardcode
          props.item.id === 31 ? (
            <div className='bcoin-modal-description'>
              <a
                href='https://www.bodo.ua/ua/?price_min=100&price_max=1000'
                target='_blank'
                rel='noreferrer'
              >
                Переглянути тут
              </a>
            </div>
          ) : null
        }

        {characteristics ? (
          <BCoinItemsCharacteristics characteristics={characteristics} />
        ) : null}
      </div>

      <div className='bcoin-modal-footer'>
        <ItemPrice {...props} />

        <div className='bcoin-modal-button-container'>
          <Button
            styleClass={classNames('btn__bcoin-blue-xl', {
              ['disabled']: disableConfirmButton,
            })}
            label='Order'
            onClick={disableConfirmButton ? undefined : handleNext}
            hasTooltip
          />
        </div>
      </div>
    </div>
  );
};

export default BCoinModalData;
