import { useState, useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { valuesInitialState, reducer } from '../../../../reducer/reducer';
import { ON_TOGGLE_VIEW } from '../../../../store/actions';
import { BCoinMyHubReturn } from './types';
import { Tab } from './components/Tabs/types';
import { BCoinAchievementDto } from '../../../../api/api-types';

export const useBCoinMyHub = (tabs: Tab[]): BCoinMyHubReturn => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].name);
  const [selectedAchievement, setSelectedAchievement] =
    useState<BCoinAchievementDto | null>(null);

  const { t } = useTranslation();

  const [element, setElement] = useState<HTMLDivElement>();
  const [handleValues, setHandleValues] = useReducer(
    reducer,
    valuesInitialState(t),
  );
  const { toggleViewAchievementItem } = handleValues;

  const toggleViewAchievement = (achievement: BCoinAchievementDto) => {
    setSelectedAchievement(achievement);
    setHandleValues(
      ON_TOGGLE_VIEW({
        payload: {
          toggleViewAchievementItem: !toggleViewAchievementItem,
        },
      }),
    );
  };

  useEffect(() => {
    const el = document.getElementById('root') as HTMLDivElement;

    if (el) {
      setElement(el);
    }
  }, []);

  if (!element) {
    return {
      activeTab,
      element: null,
      selectedAchievement,
      setActiveTab,
      toggleViewAchievementItem,
      toggleViewAchievement,
    };
  }

  return {
    activeTab,
    element,
    selectedAchievement,
    setActiveTab,
    toggleViewAchievementItem,
    toggleViewAchievement,
  };
};
