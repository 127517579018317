import {
  findPreviousMonthDate,
  formatCurrentToISO,
  formatSelectedMonth,
} from '../utils/date-formatting';
import { dateToday } from '../constants/date-values';
import { RootState } from '../store';
import { Msg } from '../store/actions';
import { defaultAccrualType } from '../constants/select-values';
import { TranslationFunction } from '../components/type-helpers/translation';

// TODO: find better way to work with translations
/**
 * @deprecated To be removed, prefer global state in `/src/store/reducer.ts` instead
 */
export const valuesInitialState = (t: TranslationFunction) => ({
  accrualTypeFilter: [defaultAccrualType(t)],
  clientId: null,
  customerName: '',
  currentDate: new Date().toUTCString(),
  currentDates: [],
  currentHour: '',
  currentMinute: '',
  currentPage: 0,
  comment: '',
  dayOffType: '',
  departmentId: 0,
  departmentName: '',
  editValuesMode: false,
  employeeId: null,
  endDate: null,
  fileName: '',
  halfDay: false,
  isLoading: false,
  pageData: {
    totalAmount: 0,
  },
  projectId: null,
  projectManagerName: '',
  projectName: '',
  ratePerOvertime: 0,
  ratePerPartTime: 0,
  reportStartDate: formatCurrentToISO(
    findPreviousMonthDate(new Date().toUTCString(), 2),
  ),
  reportEndDate: new Date().toUTCString(),
  rowsPerPage: 20,
  startDate: null,
  selectedPMId: '',
  selectedPMName: '',
  selectedMonth: formatSelectedMonth(dateToday),
  selectedStatus: t('status.value.0'),
  selectedTask: {},
  sortByFilter: '',
  taskId: '',
  taskName: '',
  timeLogId: '',
  toggleAddMember: false,
  toggleAddProject: false,
  toggleEditItem: false,
  toggleEditMember: false,
  toggleEditProject: false,
  toggleStatusValue: true,
  toggleToolTip: true,
  toggleViewStoreItem: false,
  toggleViewAchievementItem: false,
  toggleViewValue: true,
  typeOfReport: t('reports.types.value.by_client'),
  userId: '',
  userName: '',
  employeeTaskName: '',
});

const updateState = (state: RootState, action: Msg) => ({
  ...state,
  ...action.payload,
});
/**
 * @deprecated move to using reducer from `/src/store/reducer.ts` instead
 */
export const reducer = (state: RootState, action: Msg) => {
  switch (action.type) {
    case 'CLEAR_TEXT':
    case 'EDIT_MODE':
    case 'ON_TOGGLE_VIEW':
    case 'SELECT_DATA':
    case 'SET_CURRENT_PAGE':
    case 'SET_DATE_VALUE':
    case 'SET_DATE_VALUES_ARRAY':
    case 'SET_END_DATE':
    case 'SET_FILE':
    case 'SET_FILE_NAME':
    case 'SET_ID':
    case 'SET_LOADER':
    case 'SET_NEXT_PAGE':
    case 'SET_PAGE_DATA':
    case 'SET_PREVIOUS_PAGE':
    case 'SET_SELECTABLES':
    case 'SET_SELECTED_PAGE':
    case 'SET_START_DATE':
    case 'SET_TEXT':
      return updateState(state, action);
    default:
      return state;
  }
};
