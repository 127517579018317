import styles from './Tabs.module.scss';
import { TabsProps } from './types';
import Tab from './components/Tab';
import { FC } from 'react';

const Tabs: FC<TabsProps> = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div className={styles.tabsContainer}>
      {tabs.map((tab) => (
        <Tab
          key={tab.name}
          tab={tab}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      ))}
    </div>
  );
};

export default Tabs;
