import React from 'react';
import { lastDayOfMonth } from 'date-fns';
import styles from '../../styles/time-tracking.module.scss';

import StyledCalendarDatePicker from '../../components/DatePickers/DatePicker/StyledCalendarDatePicker';
import { handleHolidayArr } from '../../utils/handleHolidayArr';
import renderDayContents from '../../modules/TimeTracking/DayContentBlock';
import { formatDate } from '../../utils/date-formatting';
import ReportTable from '../../components/Tables/ReportTable';
import { displayBillable } from '../../utils/conditionalRowStyles';
import Loader from '../../components/Loader/Loader';
import HeaderBlock from '../../modules/TimeTracking/HeaderBlock/HeaderBlock';
import FormBlock from '../../modules/TimeTracking/FormBlock';
import { useTimeTrackingPage } from './useTimeTrackingPage';
import { useDateSelection } from './hooks/useDateSelection';
import { useMultiDateToolTip } from './components/MultiDatesToolTip/useMultiDateToolTip';
import MultiDatesToolTip from './components/MultiDatesToolTip/MultiDatesToolTip';

const TimeTrackingPage = () => {
  const {
    activeRow,
    comment,
    currentDate,
    currentHour,
    currentMinute,
    editMode,
    employeeData,
    filteredEmployeeData,
    getTimeLogsList,
    handleGlobalEditMode,
    handleMonthChange,
    isLoading,
    projectId,
    projectName,
    selectedCurrentMinute,
    selectedProjectName,
    setActiveRow,
    setHandleValues,
    t,
    tableRef,
    taskId,
    taskName,
    timeLogId,
    timeTrackedData,
    timeTrackingColumns,
    currentDates,
  } = useTimeTrackingPage();

  const { selectedDates, onChangeHandler, isCommandOrCtrlPressed } =
    useDateSelection(setHandleValues, handleGlobalEditMode, currentDate);

  const {
    isTooltipVisible,
    handleTooltipHover,
    handleTooltipMouseEnter,
    handleTooltipMouseLeave,
    handleOkClick,
    handleDontShowAgainClick,
    canShowTooltip,
  } = useMultiDateToolTip();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>{t('time_tracking.title')}</h2>
          <HeaderBlock
            currentDate={currentDate}
            getTimeLogsList={getTimeLogsList}
            setHandleValues={setHandleValues}
          />
        </div>
        <div
          className={styles.data}
          onMouseEnter={handleTooltipMouseEnter}
          onMouseLeave={handleTooltipMouseLeave}
        >
          <FormBlock
            {...{
              activeRow,
              comment,
              currentDate,
              currentHour:
                currentHour ||
                employeeData.lastUsedProject?.duration?.hours?.toString(),
              currentMinute:
                currentMinute ||
                employeeData.lastUsedProject?.duration?.minutes?.toString(),
              editValuesMode: editMode,
              employeeData,
              filteredEmployeeData,
              getTimeLogsList,
              handleGlobalEditMode,
              projectId,
              projectName,
              selectedCurrentMinute,
              selectedProjectName,
              setActiveRow,
              setHandleValues,
              taskId: taskId || employeeData.lastUsedProject?.eptId,
              taskName,
              timeLogId,
              currentDates,
            }}
          />

          <div className={styles.datePicker} data-tooltip-id='multi-time-log'>
            <StyledCalendarDatePicker
              wrapperClassName='styledCalendar'
              startDate={new Date(currentDate)}
              maxDate={lastDayOfMonth(new Date())}
              highlightDates={[
                ...(handleHolidayArr(timeTrackedData?.holidays) || []),
                ...selectedDates,
              ]}
              onChange={(selected) => onChangeHandler(selected)}
              onMonthChange={handleMonthChange}
              renderDayContents={(day, date) =>
                renderDayContents(timeTrackedData, day, date, selectedDates)
              }
            />
            <div className={styles.datePickerSummary}>
              <div className={styles.datePickerSummaryCol}>
                {`${t(
                  'time_tracking.total_values.week_total',
                )}: ${timeTrackedData?.currentWeek}/${timeTrackedData?.weekTotal}`}
              </div>
              <div className={styles.datePickerSummaryCol}>
                <div className={styles.dateValue}>
                  <div className={styles.datePickerSummaryCol}>
                    {`${t('time_tracking.total_values.month_total')}:`}
                  </div>
                  <div className={styles.datePickerSummaryCol}>
                    {`${timeTrackedData?.currentMonth}/${timeTrackedData?.monthTotal}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(isTooltipVisible || isCommandOrCtrlPressed) && (
        <MultiDatesToolTip
          {...{
            isTooltipVisible,
            handleTooltipHover,
            handleTooltipMouseLeave,
            handleOkClick,
            handleDontShowAgainClick,
            isCommandOrCtrlPressed,
            canShowTooltip,
          }}
        />
      )}

      {/* Table Section */}
      <div className={styles.container}>
        {timeTrackedData && !isLoading && (
          <>
            <div className={styles.infoRow}>
              <span className={styles.infoRowDate}>
                {formatDate(currentDate)(t)}
              </span>
              <span className={styles.infoRowTime}>
                {`${t(
                  'time_tracking.total_values.day_total',
                )}: ${timeTrackedData?.dayCurrent}/${timeTrackedData?.dayTotal}`}
              </span>
            </div>

            <ReportTable
              onRowClick={() => {}}
              ref={tableRef}
              columns={timeTrackingColumns}
              data={timeTrackedData?.timeLogs}
              tableName='time-tracking-list'
              editRowId={activeRow}
              changeRowColor={displayBillable}
            />
          </>
        )}

        {!timeTrackedData?.timeLogs?.length && !isLoading && (
          <p>{t('warning.no_found_data')}</p>
        )}
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default TimeTrackingPage;
