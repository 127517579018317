import classNames from 'classnames';
import { ReactComponent as CloseXLIcon } from '../../../../icons/CloseXLIcon.svg';
import styles from './BCoinAcievementItemModal.module.scss';
import { BCoinAcievementItemModalProps } from './types';

const BCoinAchievementItemModal = ({
  onClick,
  selectedAchievement,
}: BCoinAcievementItemModalProps) => {
  return (
    <div className={styles.layout}>
      <div className={styles.modalContainer}>
        <div className={styles.mainInfo}>
          <div
            className={classNames(styles.circle, {
              [styles.inactive]: !selectedAchievement.completed,
            })}
          >
            <img
              src={selectedAchievement?.image ?? '/achievement-icon.png'}
              alt='achievement'
              className={classNames({
                [styles.inactive]: !selectedAchievement.completed,
              })}
            />
          </div>

          <div className={styles.name}>{selectedAchievement.name}</div>
          <div className={styles.description}>
            {selectedAchievement.description}
          </div>
        </div>

        <div className={styles.rewardInfo}>
          <div className={styles.rewardTitle}>
            {selectedAchievement.completed ? 'you got' : 'you wil get'}
          </div>
          <div className={styles.rewardValue}>
            {selectedAchievement.reward}
            <span>b-coins</span>
          </div>
        </div>

        <div className='bcoin-modal-close-btn' onClick={onClick}>
          <CloseXLIcon />
        </div>
      </div>
    </div>
  );
};

export default BCoinAchievementItemModal;
