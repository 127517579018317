import { FC, useState } from 'react';
import styles from './CompletedTasks.module.scss';
import classNames from 'classnames';
import { ReactComponent as ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon.svg';
import { BCoinTaskCompletedDto } from '../../../../../../api/api-types';
interface CompletedTasksProps {
  tasks: BCoinTaskCompletedDto[];
}

const CompletedTasks: FC<CompletedTasksProps> = ({ tasks }) => {
  const [expandedTaskId, setExpandedTaskId] = useState<number | null>(null);
  const handleExpandTask = (taskId: number) => {
    setExpandedTaskId(taskId === expandedTaskId ? null : taskId);
  };

  return (
    <div className={styles.root}>
      {tasks.map((task) => (
        <div
          key={task.id}
          onClick={() => handleExpandTask(task.id)}
          className={classNames(styles.expandableItem, {
            [styles.expanded]: task.id === expandedTaskId,
          })}
        >
          <div className={styles.taskItem}>
            <div className={styles.taskName}>{task.name}</div>
            <div className={styles.taskInfo}>
              <div className={styles.taskBcoinValue}>
                {task.reward}
                <span>b-coins</span>
              </div>
              <div
                className={classNames(styles.icon, {
                  [styles.iconExpanded]: task.id === expandedTaskId,
                })}
              >
                <ChevronDownIcon />
              </div>
            </div>
          </div>
          <div className={styles.taskDescription}>{task.description}</div>
        </div>
      ))}
    </div>
  );
};

export default CompletedTasks;
