import Button from '../../../../components/Button/Button';
import { ReactComponent as WalletIcon } from '../../../../icons/WalletIcon.svg';
import { ReactComponent as UserIcon } from '../../../../icons/UserIcon.svg';
import { ReactComponent as BinariksLogo } from '../../../../icons/BinariksLogo.svg';
import classNames from 'classnames';
import { useBCoinHeader } from './useBCoinHeader';
import { FC } from 'react';
import { BCoinHeaderProps } from './types';

const BCoinHeader: FC<BCoinHeaderProps> = ({ employeeBCoinBalance }) => {
  const { navigate, isBalance, isMyHub } = useBCoinHeader();

  return (
    <div className='bcoinheader'>
      <div className='bcoinheader__logo' onClick={() => navigate('/')}>
        <BinariksLogo />
        <div className='back-button'>&#8592; back </div>
      </div>
      <div className='bcoinheader__btn-container'>
        <div
          className='bcoin-balance-container'
          onClick={() => navigate('/b-coin/balance')}
        >
          <Button
            styleClass={classNames('balance-button', {
              'balance-button-active': isBalance,
            })}
            icon={<WalletIcon />}
          />
          <div className='balance-info'>
            <div className='balance-text'>your balance</div>
            <div className='balance-value'>
              {employeeBCoinBalance}
              <span>b-coins</span>
            </div>
          </div>
        </div>
        <div className='vertical-divider' />
        <Button
          styleClass={classNames('my-hub-button', {
            'my-hub-button-active': isMyHub,
          })}
          label='My Hub'
          icon={<UserIcon />}
          onClick={() => navigate('/b-coin/my-hub')}
        />
        <a
          href='https://contacts.google.com/b-coin@binariks.com?hl=en'
          target='_blank'
          rel='noreferrer'
        >
          <Button styleClass='btn__bcoin-contact' label='Contact Marvin' />
        </a>
      </div>
    </div>
  );
};

export default BCoinHeader;
