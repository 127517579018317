export const fileTooBig = 'File size should be less than 1MB';

export const namespace = 'http://binariks.com';

export const noDeletePM = 'Initially assigned PM cannot be deleted';

export const timeIntervalOverlaps =
  'Time interval overlaps with existing records!';

export const policyWarning =
  'Vacation may be requested only by those employees, who have been working at least 5 months in the company.';
export const EPTNotFound = 'Employee Project Task is not found';
