/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CustomerGetDto,
  CustomerLocationDto,
  CustomerProjectsDto,
  CustomerRequestDto,
  DepartmentDto,
  EmployeeTask,
  MonthLogs,
  NameIdDto,
  PageEmployeeProjectTaskDto,
  ProjectLastUsedDto,
  ProjectEmployeeReport,
  ProjectManagerEmployeeReport,
  ProjectManagerReportItem,
  ProjectReport,
  ProjectWebDto,
  SicknessesDto,
  TeamMemberDto,
  TotalReportDto,
  UnitDto,
  UserFullDto,
  UserNameIdDto,
  VacationList,
} from '../api/api-types';
import { clearedCustomerData } from '../constants/cleared-objects';
import { absurd } from '../utils/fp';
import { Msg } from './actions';

export type ConfirmPopUp = {
  status: boolean;
  rowId: number | null;
};

type State = {
  clientData: CustomerRequestDto | null;
  clientList: NameIdDto[];
  clientListAll: NameIdDto[];
  clientPage: CustomerGetDto | null;
  reportPM: ProjectManagerReportItem[];
  reportPMAll: ProjectManagerReportItem[];
  confirmPopUp: ConfirmPopUp;
  departmentList: DepartmentDto[];
  editMode: boolean;
  employeeData: ProjectLastUsedDto;
  employeeList: UserNameIdDto[];
  employeesByRole: UserNameIdDto[];
  isConfirmPopUpApproved: boolean;
  locationList: CustomerLocationDto[];
  memberData: any | null; // TODO: Replace 'any' with the specific type after moving to react-table v8
  memberList: PageEmployeeProjectTaskDto['content'];
  navigateUrl: string;
  pmList: UserNameIdDto[];
  pmMemberList: ProjectManagerReportItem[];
  projectData: ProjectWebDto;
  projectList: ProjectWebDto[];
  projectListAll: ProjectWebDto[];
  projectsByClientID: CustomerProjectsDto[] | null;
  projectsInfo: TeamMemberDto;
  registeredEmployee: UserFullDto;
  reportClient: TotalReportDto;
  reportPMEmployee: ProjectManagerEmployeeReport;
  reportProject: ProjectReport;
  reportProjectEmployee: ProjectEmployeeReport;
  reportTotal: TotalReportDto;
  sicknessData: SicknessesDto;
  taskData: any | null; // TODO: Replace 'any' with the specific type after moving to react-table v8
  taskList: EmployeeTask[];
  timeTrackedData: MonthLogs;
  units: UnitDto[];
  vacationData: VacationList | null;
  warningPopUp: boolean;
  employeeIdData: number | null;
  key: number;
  triggerUpdate: boolean;
  bCoinImages: HTMLImageElement[];
};

const initState: State = {
  clientData: clearedCustomerData,
  clientList: [],
  clientListAll: [],
  triggerUpdate: false,
  clientPage: null,
  confirmPopUp: { status: false, rowId: null },
  departmentList: [],
  editMode: false,
  employeeData: {
    projectDTOList: [],
    lastUsedProject: {
      eptId: undefined,
      nameProject: undefined,
      nameTask: undefined,
      duration: undefined,
    },
  },
  employeeList: [],
  employeesByRole: [],
  isConfirmPopUpApproved: false,
  locationList: [],
  memberData: null,
  memberList: [],
  navigateUrl: '',
  pmList: [],
  pmMemberList: [],
  projectData: {},
  projectList: [],
  projectListAll: [],
  projectsByClientID: null,
  projectsInfo: {},
  registeredEmployee: {},
  reportClient: {},
  reportPM: [],
  reportPMAll: [],
  reportPMEmployee: {},
  reportProject: {},
  reportProjectEmployee: {},
  reportTotal: {},
  sicknessData: {},
  taskData: null,
  taskList: [],
  timeTrackedData: {},
  units: [],
  vacationData: null,
  warningPopUp: false,
  employeeIdData: null,
  key: 0,
  bCoinImages: [],
};
//TODO: move logic from handling it on message level to reducer, as it should hold all business logic
const reducer = (state = initState, action: Msg): State => {
  switch (action.type) {
    case 'SET_BCOIN_IMAGES':
      return {
        ...state,
        bCoinImages: action.payload,
      };
    case 'SET_KEY':
      return {
        ...state,
        key: state.key + 1,
      };

    case 'ADD_CLIENTS_CONTACT_FIELD': {
      const { field } = action.payload;
      const customerContactPersonRequestDtos =
        state.clientData?.customerContactPersonRequestDtos ?? [];
      return {
        ...state,
        clientData: {
          ...state.clientData,
          clientName: state.clientData?.clientName ?? '',
          location: state.clientData?.location ?? [],
          customerContactPersonRequestDtos: [
            ...customerContactPersonRequestDtos,
            field,
          ],
        },
      };
    }

    case 'ADD_CLIENTS_EMAIL_FIELD': {
      const { parentIndx, field } = action.payload;

      const updatedContact =
        state.clientData?.customerContactPersonRequestDtos.map((el, i) => {
          if (i === parentIndx) {
            if (el.emailRequestSet) {
              return {
                ...el,
                emailRequestSet: [...el.emailRequestSet, field],
              };
            }
            return {
              ...el,
              emailRequestSet: [field],
            };
          }
          return el;
        });

      return {
        ...state,
        clientData: {
          ...state.clientData,
          clientName: state.clientData?.clientName ?? '',
          location: state.clientData?.location ?? [],
          customerContactPersonRequestDtos: updatedContact ?? [],
        },
      };
    }

    case 'ADD_CLIENTS_LOCATION_FIELD': {
      return {
        ...state,
        clientData: {
          ...state.clientData,
          clientName: state.clientData?.clientName ?? '',
          customerContactPersonRequestDtos:
            state.clientData?.customerContactPersonRequestDtos ?? [],
          location: [...(state.clientData?.location ?? []), action.payload],
        },
      };
    }

    case 'CHANGE_CLIENTS_UNIT_FIELD': {
      const { id, value } = action.payload;
      return {
        ...state,
        //TODO: check where unitName could be used
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        clientData: { ...state.clientData, unitId: +id, unitName: value },
      };
    }

    case 'CLEAR_CLIENT_DATA':
      return { ...state, clientPage: {} };

    case 'CLEAR_REPORT_CLIENT':
      return { ...state, reportClient: {} };

    case 'CLEAR_REPORT_PROJECT':
      return { ...state, reportProject: {} };

    case 'CLEAR_REPORT_PROJECT_EMPLOYEE':
      return { ...state, reportProjectEmployee: {} };

    case 'CLEAR_REPORT_PM':
      return { ...state, reportPM: [] };

    case 'CLEAR_REPORT_PM_ALL':
      return { ...state, reportPMAll: [] };

    case 'CLEAR_REPORT_PM_EMPLOYEE':
      return { ...state, reportPMEmployee: {} };

    case 'CLEAR_REPORT_TOTAL':
      return { ...state, reportTotal: {} };

    case 'CLEAR_TEAM_MEMBERS':
      return { ...state, memberList: [] };

    case 'GET_CLIENTS':
      return { ...state, clientList: action.payload ? action.payload : [] };

    case 'UPDATE_CLIENT_PAGE':
      return {
        ...state,
        triggerUpdate: !state.triggerUpdate,
      };

    case 'GET_CLIENTS_ALL':
      return { ...state, clientListAll: action.payload ? action.payload : [] };

    case 'GET_CLIENT_DATA':
      return { ...state, clientPage: { ...action.payload } };

    case 'GET_DEPARTMENTS':
      return { ...state, departmentList: [...action.payload] };

    case 'GET_EMPLOYEES':
      return { ...state, employeeList: [...action.payload] };

    case 'GET_EMPLOYEES_BY_ROLE':
      return { ...state, employeesByRole: action.payload };

    case 'GET_EMPLOYEE_DATA':
      return { ...state, employeeData: action.payload };

    case 'GET_LOCATIONS':
      return { ...state, locationList: [...action.payload] };

    case 'GET_MEMBER_ID':
      return { ...state, memberData: action.payload };

    case 'GET_PM':
      return { ...state, pmList: [...action.payload] };

    case 'GET_PM_TEAM':
      return { ...state, pmMemberList: action.payload };

    case 'GET_PROJECTS':
      return { ...state, projectList: [...action.payload] };

    case 'GET_PROJECTS_ALL':
      return { ...state, projectListAll: [...action.payload] };

    case 'GET_PROJECTS_BY_CLIENT_ID':
      return { ...state, projectsByClientID: action.payload };

    case 'GET_PROJECTS_INFO':
      return { ...state, projectsInfo: action.payload };

    case 'GET_PROJECT_ID':
      return { ...state, projectData: action.payload };

    case 'GET_REGISTERED_EMPLOYEE':
      return { ...state, registeredEmployee: action.payload };

    case 'GET_REPORT_CLIENT': {
      return { ...state, reportClient: action.payload };
    }

    case 'GET_REPORT_TOTAL':
      return { ...state, reportTotal: action.payload };

    case 'GET_REPORT_PM':
      return { ...state, reportPM: action.payload };

    case 'GET_REPORT_PM_ALL':
      return { ...state, reportPMAll: action.payload };

    case 'GET_REPORT_PM_EMPLOYEE':
      return { ...state, reportPMEmployee: action.payload };

    case 'GET_REPORT_PROJECT_EMPLOYEE':
      return { ...state, reportProjectEmployee: action.payload };

    case 'GET_REPORT_PROJECT':
      return { ...state, reportProject: action.payload };

    case 'GET_SICKNESS_DATA':
      return { ...state, sicknessData: action.payload };

    case 'GET_TASKS':
      return { ...state, taskList: [...action.payload] };

    case 'GET_TASK_ID':
      return { ...state, taskData: action.payload };

    case 'GET_TEAM':
      return {
        ...state,
        memberList: action.payload ? [...action.payload] : [],
      };

    case 'GET_TIME_TRACKED_DATA':
      return { ...state, timeTrackedData: action.payload };

    case 'GET_UNITS':
      return { ...state, units: action.payload };

    case 'GET_VACATION_DATA':
      return { ...state, vacationData: action.payload };

    case 'REMOVE_CLIENTS_EMAIL_FIELD': {
      const { parentIndx, childIndx } = action.payload;

      const updatedContact =
        state.clientData?.customerContactPersonRequestDtos.map((el, i) => {
          if (i === parentIndx) {
            const newEmailList = el.emailRequestSet?.filter(
              (_, indx) => indx !== childIndx,
            );
            return { ...el, emailRequestSet: newEmailList };
          }
          return el;
        });

      return {
        ...state,
        clientData: {
          ...state.clientData,
          clientName: state.clientData?.clientName ?? '',
          location: state.clientData?.location ?? [],
          customerContactPersonRequestDtos: updatedContact ?? [],
        },
      };
    }

    case 'REMOVE_CLIENTS_LOCATION_FIELD': {
      const { childIndx } = action.payload;

      const locationList = state.clientData?.location ?? [];
      const updatedLocationList = locationList
        .slice(0, childIndx)
        .concat(locationList.slice(childIndx + 1));

      return {
        ...state,
        clientData: {
          ...state.clientData,
          location: updatedLocationList,
          clientName: state.clientData?.clientName ?? '',
          customerContactPersonRequestDtos:
            state.clientData?.customerContactPersonRequestDtos ?? [],
        },
      };
    }

    case 'RESET_CLIENTS_FIELD': {
      const data = action.payload;
      return { ...state, clientData: data };
    }

    case 'SET_CLIENTS_DETAILS': {
      const { key, value, parentIndx, childIndx } = action.payload;

      if (parentIndx === -1) {
        return {
          ...state,
          clientData: {
            ...state.clientData,
            clientName: state.clientData?.clientName ?? '',
            location: state.clientData?.location ?? [],
            customerContactPersonRequestDtos:
              state.clientData?.customerContactPersonRequestDtos ?? [],
            [key]: value,
          },
        };
      }
      //TODO: refactor! dangerous logic ahead with boolean indexes
      const [currentKey] = key.split(' ');
      const updatedData =
        state.clientData?.customerContactPersonRequestDtos.map((el, i) => {
          if (i === parentIndx) {
            if (childIndx !== -1) {
              const newEmailList = el.emailRequestSet?.map((elm, indx) => {
                if (indx === childIndx) {
                  return { ...elm, email: value };
                }
                return elm;
              });
              return { ...el, emailRequestSet: newEmailList };
            }
            return { ...el, [currentKey]: value };
          }
          return { ...el, [key]: value };
        });

      return {
        ...state,
        clientData: {
          ...state.clientData,
          clientName: state.clientData?.clientName ?? '',
          location: state.clientData?.location ?? [],
          customerContactPersonRequestDtos: updatedData ?? [],
        },
      };
    }

    case 'SET_CLIENTS_LOCATION_FIELD': {
      const { location, indx } = action.payload;
      const newLocation = [...(state.clientData?.location ?? [])];
      newLocation[indx] = { name: location };

      return {
        ...state,
        clientData: {
          ...state.clientData,
          clientName: state.clientData?.clientName ?? '',
          customerContactPersonRequestDtos:
            state.clientData?.customerContactPersonRequestDtos ?? [],
          location: newLocation,
        },
      };
    }

    case 'SET_CONFIRM_POP_UP': {
      return {
        ...state,
        confirmPopUp: {
          status: action.payload.status,
          rowId: action.payload.rowId,
        },
      };
    }

    case 'SET_EDIT_MODE': {
      return {
        ...state,
        editMode: action.payload,
      };
    }

    case 'SET_IS_POP_UP_APPROVED': {
      return {
        ...state,
        isConfirmPopUpApproved: action.payload,
      };
    }

    case 'SET_NAVIGATE_URL': {
      return {
        ...state,
        navigateUrl: action.payload,
      };
    }

    case 'SET_WARNING_POP_UP': {
      return {
        ...state,
        warningPopUp: action.payload,
      };
    }

    case 'UPDATE_PROJECT': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'CLEAR_TEXT': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'EDIT_MODE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'ON_TOGGLE_VIEW': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SELECT_DATA': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_CURRENT_PAGE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_DATE_VALUE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_DATE_VALUES_ARRAY': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_END_DATE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_FILE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_FILE_NAME': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_ID': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_LOADER': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_NEXT_PAGE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_PAGE_DATA': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_PREVIOUS_PAGE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_SELECTABLES': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_SELECTED_PAGE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_START_DATE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_TEXT': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      absurd(action);
      return state;
  }
};
export default reducer;
