import React from 'react';

const HeartIcon = ({ stroke = '#FDFDFD', fill = 'none' }) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M27.6368 6.27412C26.9645 5.60145 26.1662 5.06785 25.2876 4.70379C24.4089 4.33973 23.4672 4.15234 22.5161 4.15234C21.5651 4.15234 20.6233 4.33973 19.7447 4.70379C18.8661 5.06785 18.0678 5.60145 17.3954 6.27412L16.0001 7.66948L14.6047 6.27412C13.2466 4.91602 11.4046 4.15305 9.48397 4.15305C7.56333 4.15305 5.72136 4.91602 4.36326 6.27412C3.00516 7.63222 2.24219 9.47419 2.24219 11.3948C2.24219 13.3155 3.00516 15.1575 4.36326 16.5155L5.75862 17.9109L16.0001 28.1523L26.2415 17.9109L27.6368 16.5155C28.3095 15.8432 28.8431 15.0449 29.2072 14.1663C29.5712 13.2877 29.7586 12.3459 29.7586 11.3948C29.7586 10.4438 29.5712 9.50202 29.2072 8.62339C28.8431 7.74476 28.3095 6.94647 27.6368 6.27412Z'
        stroke={stroke}
        fill={fill}
        strokeWidth='1.5'
      />
    </svg>
  );
};

export default HeartIcon;
