import { useAuth0 } from '@auth0/auth0-react';
import LoginPage from './pages/LoginPage';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import 'react-toastify/dist/ReactToastify.css';
import MenuBarContainer from './components/MenuBar/MenuBar';
import MenuRoutes from './routes/MenuRoutes';
import { axiosInstance } from './api/config';
import { useLocation } from 'react-router-dom';

const App = () => {
  const { isAuthenticated, getAccessTokenSilently, logout, error } = useAuth0();
  const [isTokenReady, setIsTokenReady] = useState(false);
  const location = useLocation();

  const hideMenuBar = location.pathname.includes('b-coin');

  axiosInstance.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response.status === 401) {
        logout({ returnTo: document.location.origin });
      }
      return Promise.reject(err);
    },
  );

  useEffect(() => {
    let logoutTimeout: NodeJS.Timeout | null = null;
    const updateAuthentication = async () => {
      if (!isAuthenticated) return;
      if (error) {
        logout({ returnTo: document.location.origin });
        return;
      }
      try {
        const accessToken = await getAccessTokenSilently();
        sessionStorage.setItem('accessToken', accessToken);

        const decodedToken = jwtDecode(accessToken);
        const currentTime = Date.now();
        const expirationTime = decodedToken.exp
          ? decodedToken.exp * 1000
          : currentTime;
        const timeUntilExpiration = expirationTime - currentTime;

        logoutTimeout = setTimeout(() => {
          logout({ returnTo: window.location.origin });
        }, timeUntilExpiration);

        setIsTokenReady(true);
      } catch (err) {
        console.log('Error getting or decoding token:', err);
      }
    };

    updateAuthentication();

    return () => {
      if (logoutTimeout) {
        clearTimeout(logoutTimeout);
      }
    };
  }, [isAuthenticated, error]);

  return isAuthenticated && isTokenReady ? (
    <div>
      {!hideMenuBar && <MenuBarContainer />}
      <MenuRoutes />
      <ToastContainer position='bottom-left' closeOnClick theme='colored' />
    </div>
  ) : (
    <LoginPage />
  );
};
export default App;
