import RobotLearnsImage from '../../../../images/RobotLearnsImage.png';
import BCoinBodyLeftCol from '../../components/BCoinBodyLeftCol';
import BCoinItemsList from '../../components/BCoinItemsList';
import Loader from '../../../../components/Loader/Loader';
import { ReactComponent as SearchIcon } from '../../../../icons/SearchIcon.svg';
import { useBCoinWhatToGet } from './useBCoinWhatToGet';
import { useState, Fragment, useMemo } from 'react';
import { sortRewards } from './utils/rewardSorting';

const TABS = [
  { label: 'High Price', value: 'highPrice' },
  { label: 'Low Price', value: 'lowPrice' },
  { label: 'Newest', value: 'newest' },
];

const BCoinWhatToGet = () => {
  const {
    isLoading,
    setIsLoading,
    searchQuery,
    handleSearchChange,
    filteredRewards,
    employeeBCoinBalance,
  } = useBCoinWhatToGet();

  const [activeFilter, setActiveFilter] = useState<string>(TABS[0].value);
  const sortedRewards = useMemo(() => {
    return sortRewards({ filteredRewards, activeFilter, tabs: TABS });
  }, [filteredRewards, activeFilter]);

  const handleFilterClick = (filter: string) => {
    setIsLoading(true);
    setActiveFilter(filter);
    setIsLoading(false);
  };

  return (
    <div className='bcoin-body'>
      <BCoinBodyLeftCol
        imageAsset={RobotLearnsImage}
        imageClassName='bcoin-body-left-col-img'
        title='b-coin'
        subtitle='store'
        titleClassName='bcoin-what-to-get-title'
        subtitleClassName='bcoin-what-to-get-subtitle'
        className='bcoin-what-to-get-left-col'
      />
      <div className='bcoin-body-right-col bcoin-body-what-to-get'>
        <header className='bcoin-rewards-header'>
          <div className='inputWithIcon'>
            <SearchIcon className='icon' />
            <input
              type='text'
              placeholder='Search...'
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className='bcoin-rewards-filter-tabs'>
            {TABS.map((tab, index) => (
              <Fragment key={tab.value}>
                <span
                  className={activeFilter === tab.value ? 'active' : ''}
                  onClick={() => handleFilterClick(tab.value)}
                >
                  {tab.label}
                </span>
                {index < TABS.length - 1 && <div className='separator' />}
              </Fragment>
            ))}
          </div>
        </header>
        {sortedRewards.length ? (
          <BCoinItemsList
            isLoading={isLoading}
            items={sortedRewards}
            employeeBCoinBalance={employeeBCoinBalance}
          />
        ) : (
          <p>No rewards found.</p>
        )}
      </div>
      {isLoading ? <Loader /> : null}
    </div>
  );
};

export default BCoinWhatToGet;
