import { FC } from 'react';
import styles from '../../ProductForm.module.scss';
import { ReactComponent as RulerIcon } from '../../../../../../../../icons/RulerIcon.svg';
import { SizeChartsProps } from './types';

const SizeCharts: FC<SizeChartsProps> = ({
  sizeChartRef,
  setTooltipVisible,
}) => {
  return (
    <div className={styles.sizeCharts}>
      <RulerIcon />
      <span
        ref={sizeChartRef}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      >
        Size charts
      </span>
    </div>
  );
};

export default SizeCharts;
