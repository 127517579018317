import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import BCoinBodyLeftCol from '../../components/BCoinBodyLeftCol';
import BCoinAchievementItemModal from '../../components/BCoinAcievementItemModal/BCoinAcievementItemModal';
import MyHubContent from './components/MyHubContent/MyHubContent';
import Tabs from './components/Tabs/Tabs';
import Loader from '../../../../components/Loader/Loader';
import { myHubDescriptionText } from './constants';
import { useBCoinMyHub } from './useBCoinMyHub';
import {
  getBCoinAchievements,
  getBCoinRewardWishlist,
  getEmployeeBCoinBalance,
  getEmployeeBCoinTasksCompleted,
} from '../../../../api/bcoin';
import { handleError } from '../../../../api/config';
import styles from './BCoinMyHub.module.scss';
import {
  BCoinAchievementDto,
  BCoinRewardDto,
  BCoinTaskCompletedDto,
} from '../../../../api/api-types';

const BCoinMyHub = () => {
  const [wishlistRewards, setWishlistRewards] = useState<BCoinRewardDto[]>([]);
  const [achievements, setAchievements] = useState<BCoinAchievementDto[]>([]);
  const [completedTasks, setCompletedTasks] = useState<BCoinTaskCompletedDto[]>(
    [],
  );
  const [employeeBCoinBalance, setEmployeeBCoinBalance] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const filteredCompletedTasks = completedTasks.filter(
    // displaying only tasks completed in current year
    (task) => task.reward !== 0,
  );

  const tabs = [
    { name: 'Achievements', count: achievements.length },
    { name: 'Completed Tasks', count: filteredCompletedTasks.length },
    { name: 'Wishlist', count: wishlistRewards.length },
  ];

  const {
    activeTab,
    element,
    selectedAchievement,
    setActiveTab,
    toggleViewAchievementItem,
    toggleViewAchievement,
  } = useBCoinMyHub(tabs);

  const fetchWishlistData = useCallback(async () => {
    try {
      setIsLoading(true);
      const wishlistRewards = await getBCoinRewardWishlist();
      setWishlistRewards(wishlistRewards || []);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const [achievementsData, completedTasksData, balanceData] =
        await Promise.all([
          getBCoinAchievements(),
          getEmployeeBCoinTasksCompleted(),
          getEmployeeBCoinBalance(),
        ]);
      setAchievements(achievementsData || []);
      setCompletedTasks(completedTasksData || []);
      setEmployeeBCoinBalance(balanceData || 0);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
    fetchWishlistData();
  }, [fetchData, fetchWishlistData]);

  return (
    <div className='bcoin-body'>
      <BCoinBodyLeftCol
        title='My hub'
        className='bcoin-what-to-get-left-col'
        richText={myHubDescriptionText}
      />

      <div className={styles.content}>
        <header className={styles.header}>
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
        </header>

        <div className={styles.tabContent}>
          <MyHubContent
            activeTab={activeTab}
            toggleViewAchievementItem={toggleViewAchievement}
            achievements={achievements}
            completedTasks={filteredCompletedTasks}
            wishlistRewards={wishlistRewards}
            employeeBCoinBalance={employeeBCoinBalance}
            refreshData={fetchWishlistData}
          />
        </div>
      </div>

      {isLoading && <Loader />}

      {toggleViewAchievementItem &&
        ReactDOM.createPortal(
          <BCoinAchievementItemModal
            onClick={() =>
              toggleViewAchievement(selectedAchievement as BCoinAchievementDto)
            }
            selectedAchievement={selectedAchievement as BCoinAchievementDto}
            achievements={achievements}
          />,
          element as HTMLDivElement,
        )}
    </div>
  );
};

export default BCoinMyHub;
