import React, { FC } from 'react';
import { formatExchangeValue } from '../../../../../utils/text-handling';
import { BCoinRewardDto } from '../../../../../api/api-types';

interface ItemPriceProps {
  item: BCoinRewardDto;
}

const ItemPrice: FC<ItemPriceProps> = ({ item }) => {
  return (
    <>
      {
        // TODO: contact backend to define different types of rewards and remove this hardcode
        item.id === 71 ? (
          formatExchangeValue(
            '1 b-coin = 1 usd',
            'item-card-price-currency',
            'item-currency-value',
          )
        ) : (
          <>
            <div className='item-card-price-value'>{item.price}</div>
            <div className='item-card-price-currency'>b-coins</div>
          </>
        )
      }
    </>
  );
};

export default ItemPrice;
