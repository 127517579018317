import React, { FC } from 'react';
import BCoinBadge from '../../../../../images/BCoinBadge.png';

const MadeInUkraineBadge: FC = () => {
  return (
    <>
      <img src={BCoinBadge} alt='Made in Ukraine' className='item-card-badge' />
    </>
  );
};

export default MadeInUkraineBadge;
