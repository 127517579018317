import { BCoinRewardDto } from '../../../../../api/api-types';

interface RewardSortingProps {
  filteredRewards: BCoinRewardDto[];
  activeFilter: string;
  tabs: { label: string; value: string }[];
}

export const sortRewards = ({
  filteredRewards,
  activeFilter,
  tabs,
}: RewardSortingProps): BCoinRewardDto[] => {
  if (activeFilter === tabs[2].value) {
    return filteredRewards
      .filter((reward) => reward.id > 71)
      .sort((a, b) => (b.price ?? 0) - (a.price ?? 0));
  }

  return [...filteredRewards].sort((a, b) => {
    if (activeFilter === tabs[0].value) {
      return (b.price ?? 0) - (a.price ?? 0);
    }
    if (activeFilter === tabs[1].value) {
      return (a.price ?? 0) - (b.price ?? 0);
    }
    return 0;
  });
};
