import { FC } from 'react';
import styles from '../../ProductForm.module.scss';
import { ButtonBlockProps } from './types';

const ButtonBlock: FC<ButtonBlockProps> = ({
  handlePrev,
  confirmCheckoutHandler,
}) => {
  return (
    <div className={styles.actions}>
      <button type='button' className={styles.backBtn} onClick={handlePrev}>
        Back
      </button>
      <button
        type='button'
        className={styles.confirmBtn}
        onClick={confirmCheckoutHandler}
      >
        Confirm
      </button>
    </div>
  );
};

export default ButtonBlock;
