import 'slick-carousel/slick/slick.css';
import { ReactComponent as CloseXLIcon } from '../../../../icons/CloseXLIcon.svg';
import Loader from '../../../../components/Loader/Loader';
import { BCoinItemModalProps } from './types';
import { useBCoinItemModal } from './useBCoinItemModal';
import BCoinItemModalSlider from './components/BCoinItemModalSlider';
import BCoinModalData from './components/BCoinModalData';
import { FC } from 'react';
import ProductForm from './components/ProductForm/ProductForm';
import ConfirmationForm from './components/ConfirmationForm/ConfirmationForm';
import CheckoutSuccessfull from './components/CheckoutSuccessfull/CheckoutSuccessfull';
import classNames from 'classnames';

const BCoinItemModal: FC<BCoinItemModalProps> = (props) => {
  const {
    characteristics,
    containerRef,
    disableConfirmButton,
    displaySuccessOpacity,
    displaySuccessfull,
    handleNext,
    handlePrev,
    images,
    isLoading,
    itemId,
    setIsLoading,
    slideIndex,
    slider,
    successfullIsVisible,
  } = useBCoinItemModal(props);

  return (
    <div className='bcoin-modal'>
      <div className='bcoin-modal-container'>
        <div ref={containerRef} className='bcoin-modal-carousel-wrapper'>
          {images.length ? (
            <BCoinItemModalSlider
              {...{
                images,
                slider,
                props,
              }}
            />
          ) : null}
        </div>

        <div className='bcoin-modal-content'>
          <div
            className='bcoin-slide-container'
            style={{
              transform: `translateX(-${slideIndex * 50}%)`,
            }}
          >
            <div className='bcoin-slide-content'>
              <BCoinModalData
                {...{
                  disableConfirmButton,
                  characteristics,
                  props,
                  handleNext,
                }}
              />
            </div>
            <div className='bcoin-slide-content'>
              <div className='bcoin-product-form-slide'>
                {props.item.name === 'Charity' ? (
                  <ConfirmationForm
                    {...{
                      handlePrev,
                      bcoinAmmount: props.employeeBCoinBalance,
                      itemId,
                      displaySuccessfull,
                      setIsLoading,
                    }}
                  />
                ) : (
                  <ProductForm
                    {...{
                      handlePrev,
                      disableConfirmButton,
                      displaySuccessfull,
                      item: props.item,
                      setIsLoading,
                    }}
                  />
                )}
              </div>

              {successfullIsVisible && (
                <div
                  className={classNames(
                    'bcoin-product-form-slide',
                    'successfull-slide',
                    {
                      active: displaySuccessOpacity,
                    },
                  )}
                >
                  <CheckoutSuccessfull />
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className='bcoin-modal-close-btn'
          onClick={props.onCancelButtonClick}
        >
          <CloseXLIcon />
        </div>
      </div>

      {isLoading ? <Loader /> : null}
    </div>
  );
};

export default BCoinItemModal;
