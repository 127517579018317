import { useLocation, useNavigate } from 'react-router-dom';
import { UseBCoinHeaderReturn } from './types';

export const useBCoinHeader = (): UseBCoinHeaderReturn => {
  const navigate = useNavigate();
  const location = useLocation();
  const isBalance = location.pathname === '/b-coin/balance';
  const isMyHub = location.pathname === '/b-coin/my-hub';

  return {
    isBalance,
    isMyHub,
    navigate,
  };
};
