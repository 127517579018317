import { useState, useEffect } from 'react';
import { SizeToolTipProps } from './types';

export const useSizeToolTip = ({ targetRef, visible }: SizeToolTipProps) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (targetRef.current && visible) {
      const rect = targetRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top - rect.height,
        left: rect.left + rect.width,
      });
    }
  }, [targetRef, visible]);

  return position;
};
