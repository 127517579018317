import { useLocation, useNavigate, NavigateFunction } from 'react-router-dom';
import { bCoinTabs } from './constants';
import { useEffect, useState } from 'react';
import { getEmployeeBCoinBalance } from '../../api/bcoin';
import { handleError } from '../../api/config';
import { UseBCoinPageReturn } from './types';

export const useBCoinPage = (): UseBCoinPageReturn => {
  const navigate: NavigateFunction = useNavigate();

  const location = useLocation();

  const [employeeBCoinBalance, setEmployeeBCoinBalance] = useState<
    number | null
  >(null);

  useEffect(() => {
    const getBalance = async () => {
      try {
        const bcoinBalance = await getEmployeeBCoinBalance();

        setEmployeeBCoinBalance(bcoinBalance);
      } catch (error) {
        handleError(error);
      }
    };

    getBalance();
  }, []);

  useEffect(() => {
    if (
      location.pathname.split('/').length < 3 ||
      location.pathname.split('/')[2] === ''
    ) {
      navigate(`/b-coin/${bCoinTabs[0].id}`);
    }
  }, [location]);

  return { navigate, employeeBCoinBalance };
};
