import { useRef, useState } from 'react';
import { RewardCheckoutFormDTO } from '../../../../../../api/api-types';
import { exchangeBCoinsForReward } from '../../../../../../api/bcoin';
import { FormData, UseProductFormProps, UseProductFormReturn } from './types';
import { useAppSelector } from '../../../../../../hooks/redux';

const POST_DEPARTMENTS_URL =
  'https://novapost.com/uk-ua/departments/?city=118064';

export const useProductForm = ({
  displaySuccessfull,
  item,
  setIsLoading,
}: UseProductFormProps): UseProductFormReturn => {
  const { id, properties, sizeTable } = item;
  const sizeChartRef = useRef<HTMLDivElement>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const {
    registeredEmployee: { name },
  } = useAppSelector((state) => state);

  const initialFormData: FormData = {
    rewardId: id || 0,
    fullName: name || '',
    phoneNumber: '',
    deliveryAddress: '',
    ...Object.keys(properties || {}).reduce(
      (acc, key) => {
        acc[key.toLowerCase()] = properties?.[key]?.[0] || '';
        return acc;
      },
      {} as Record<string, string>,
    ),
  };

  const [formData, setFormData] = useState(initialFormData);

  const [formErrors, setFormErrors] = useState({
    fullName: false,
    phoneNumber: false,
    deliveryAddress: false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (formErrors[name as keyof typeof formErrors]) {
      setFormErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };

  const handlePropertyChange = (key: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [key.toLowerCase()]: value }));
  };

  const handleIconClick = () => {
    window.open(POST_DEPARTMENTS_URL, '_blank');
  };

  const confirmCheckoutHandler = async () => {
    const errors = {
      fullName: !formData.fullName.trim(),
      phoneNumber: !formData.phoneNumber.trim(),
      deliveryAddress: !formData.deliveryAddress.trim(),
    };

    setFormErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      return;
    }

    if (displaySuccessfull && id) {
      setIsLoading(true);

      await exchangeBCoinsForReward(formData as RewardCheckoutFormDTO);
      displaySuccessfull();

      setIsLoading(false);
    }
  };

  const hasSizeProperty =
    properties && Object.keys(properties).some((key) => key === 'Size');

  return {
    formData,
    formErrors,
    handleInputChange,
    handlePropertyChange,
    handleIconClick,
    confirmCheckoutHandler,
    hasSizeProperty,
    properties,
    sizeTable,
    sizeChartRef,
    tooltipVisible,
    setTooltipVisible,
  };
};
