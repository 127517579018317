import ReactDOM from 'react-dom';
import styles from './SizeTooltip.module.scss';
import { useSizeToolTip } from './useSizeToolTip';
import { SizeTooltipProps } from './types';
import { FC } from 'react';

const SizeTooltip: FC<SizeTooltipProps> = ({
  children,
  visible,
  targetRef,
}) => {
  const position = useSizeToolTip({ targetRef, visible });

  if (!visible) return null;

  return ReactDOM.createPortal(
    <div
      className={styles.tooltip}
      style={{
        top: position.top,
        left: position.left,
        position: 'fixed',
      }}
    >
      {children}
    </div>,
    document.body,
  );
};

export default SizeTooltip;
