export const bCoinTabs = [
  {
    id: 'what-is-it',
    label: 'What is a b-coin?',
  },
  {
    id: 'how-it-works',
    label: 'How does it work?',
  },
  {
    id: 'how-to-get',
    label: 'How to get b-coin?',
  },
  // {
  //   id: 'kudos-system',
  //   label: 'Kudos system',
  // },
  {
    id: 'what-to-get',
    label: 'Store',
  },
  {
    id: 'faq',
    label: 'FAQ',
  },
];
