import { FC } from 'react';
import styles from './Achievements.module.scss';
import { AchievementProps, AchievementsProps } from '../../types';
import classNames from 'classnames';

const Achievement: FC<AchievementProps> = ({
  achievement,
  toggleViewAchievementItem,
}) => {
  return (
    <div
      className={styles.achievementItem}
      onClick={() => toggleViewAchievementItem(achievement)}
    >
      <div className={classNames(styles.circle, {
        [styles.inactive]: !achievement.completed,
      })}>
        <img
          src={achievement.image ?? '/achievement-icon.png'}
          alt='achievement'
          className={classNames({
            [styles.inactive]: !achievement.completed,
          })}
        />
      </div>
      <div className={styles.name}>{achievement.name}</div>
    </div>
  );
};

const Achievements: FC<AchievementsProps> = ({
  achievements,
  toggleViewAchievementItem,
}) => {
  return (
    <div className={styles.root}>
      {achievements.map((achievement) => (
        <Achievement
          key={achievement.id}
          achievement={achievement}
          toggleViewAchievementItem={toggleViewAchievementItem}
        />
      ))}
    </div>
  );
};

export default Achievements;
