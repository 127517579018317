import { useState, useEffect } from 'react';
import { Msg } from '../../../store/actions';
import {
  dateArrayReducerHandler,
  dateReducerHandler,
} from '../../../utils/date-handling';

export const useDateSelection = (
  setHandleValues: React.Dispatch<Msg>,
  handleGlobalEditMode: (editMode: boolean) => void,
  currentDate: string,
) => {
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [isCommandOrCtrlPressed, setIsCommandOrCtrlPressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey || event.ctrlKey) {
        setIsCommandOrCtrlPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (!event.metaKey && !event.ctrlKey) {
        setIsCommandOrCtrlPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const onChangeHandler = (selected: Date | null) => {
    if (!selected) return;

    const currentDateObj = new Date(currentDate);
    if (
      isCommandOrCtrlPressed &&
      selected.getTime() === currentDateObj.getTime()
    ) {
      return;
    }

    handleGlobalEditMode(false);

    setHandleValues({
      type: 'EDIT_MODE',
      payload: {
        editValuesMode: false,
      },
    });

    setSelectedDates((prevDates) => {
      const isAlreadySelected = prevDates.some(
        (d) => d.getTime() === selected.getTime(),
      );

      if (isAlreadySelected && isCommandOrCtrlPressed) {
        const newDates = prevDates.filter(
          (d) => d.getTime() !== selected.getTime(),
        );
        dateArrayReducerHandler(setHandleValues, newDates);
        return newDates;
      }

      if (isCommandOrCtrlPressed) {
        const newDates = [...prevDates, selected];
        dateArrayReducerHandler(setHandleValues, newDates);
        return newDates;
      }

      dateArrayReducerHandler(setHandleValues, [selected]);
      dateReducerHandler(setHandleValues, selected);
      return [selected];
    });
  };

  return {
    selectedDates,
    onChangeHandler,
    isCommandOrCtrlPressed,
  };
};
