import React, { FC } from 'react';
import styles from './Wishlist.module.scss';
import { BCoinRewardDto } from '../../../../../../api/api-types';
import BCoinItemCard from '../../../../components/BCoinItemCard/BCoinItemCard';

interface WishlistProps {
  wishlistRewards: BCoinRewardDto[];
  employeeBCoinBalance: number;
  refreshData: () => void;
}

const Wishlist: FC<WishlistProps> = ({
  wishlistRewards,
  employeeBCoinBalance,
  refreshData,
}) => {
  return (
    <div className={styles.root}>
      {wishlistRewards.map((item) => (
        <BCoinItemCard
          key={item.id}
          item={item}
          employeeBCoinBalance={employeeBCoinBalance}
          refreshData={refreshData}
          isWishlist
        />
      ))}
    </div>
  );
};

export default Wishlist;
