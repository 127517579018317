import classNames from 'classnames';
import styles from './../Tabs.module.scss';
import { FC } from 'react';

interface TabProps {
  tab: {
    name: string;
    count: number;
  };
  activeTab: string;
  setActiveTab: (tabName: string) => void;
}

const Tab: FC<TabProps> = ({ tab, activeTab, setActiveTab }) => {
  return (
    <div
      key={tab.name}
      className={classNames(styles.tab, {
        [styles.active]: activeTab === tab.name,
      })}
      onClick={() => setActiveTab(tab.name)}
    >
      <div
        className={classNames(styles.tabName, {
          [styles.tabName_active]: activeTab === tab.name,
        })}
      >
        {tab.name}
        <span
          className={classNames({
            [styles.badgeActive]: activeTab === tab.name,
          })}
        >
          {tab.count}
        </span>
      </div>
    </div>
  );
};

export default Tab;
