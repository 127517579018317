import classNames from 'classnames';
import { BCoinEmployeeDto } from '../../../api/api-types';

type BCoinBalanceItemProps = {
  item: BCoinEmployeeDto;
  index: number;
  currentEmployeeIndex?: boolean;
};

const BCoinBalanceItem = (props: BCoinBalanceItemProps) => (
  <div
    className={classNames(
      `bcoin-balance-item-${props.index <= 2 ? props.index : 'rest'}`,
      {
        'current-employee':
         props.currentEmployeeIndex,
      },
    )}
  >
    <div className='bcoin-balance-item-name'>{props.item.name}</div>
    <div className='bcoin-balance-item-number'>
      <div className='value'>{props.item.total}</div>
      <div className='key'>b-coins</div>
    </div>
    <div className='bcoin-balance-item-number'>
      <div className='value'>{props.item.spent}</div>
      <div className='key'>b-coins</div>
    </div>
  </div>
);

export default BCoinBalanceItem;
