import styles from './CheckoutSuccessfull.module.scss';
import { ReactComponent as CheckBoxCircleOutlinedIcon } from '../../../../../../icons/CheckBoxCircleOutlinedIcon.svg';

const CheckoutSuccessfull = () => {
  return (
    <div className={styles.root}>
      <CheckBoxCircleOutlinedIcon />
      <h2>Checkout Successful</h2>
      <p>Wait for a message from Marvin 🚀</p>
    </div>
  );
};

export default CheckoutSuccessfull;
