import React, { FC } from 'react';
import Achievements from '../Achievements/Achievements';
import CompletedTasks from '../CompletedTasks/CompletedTasks';
import Wishlist from '../Wishlist/Wishlist';
import { MyHubContentProps } from './types';

const MyHubContent: FC<MyHubContentProps> = ({
  activeTab,
  toggleViewAchievementItem,
  achievements,
  completedTasks,
  wishlistRewards,
  employeeBCoinBalance,
  refreshData,
}) => {
  switch (activeTab) {
    case 'Achievements':
      return (
        <Achievements
          achievements={achievements}
          toggleViewAchievementItem={toggleViewAchievementItem}
        />
      );
    case 'Completed Tasks':
      return <CompletedTasks tasks={completedTasks} />;
    case 'Wishlist':
      return (
        <Wishlist
          wishlistRewards={wishlistRewards}
          employeeBCoinBalance={employeeBCoinBalance}
          refreshData={refreshData}
        />
      );
    default:
      return (
        <Achievements
          achievements={achievements}
          toggleViewAchievementItem={toggleViewAchievementItem}
        />
      );
  }
};

export default MyHubContent;
