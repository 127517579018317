import { UseConfirmationFormProps } from './types';

export const useConfirmationForm = ({
  itemId,
  setIsLoading,
  exchangeBCoinsForReward,
  displaySuccessfull,
}: UseConfirmationFormProps) => {
  const handleConfirm = async () => {
    if (itemId) {
      setIsLoading(true);

      const requestDTO = {
        rewardId: itemId,
      };

      await exchangeBCoinsForReward(requestDTO);
      displaySuccessfull();

      setIsLoading(false);
    }
  };

  return {
    handleConfirm,
  };
};
