import ReactDOM from 'react-dom';
import BCoinItemModal from '../BCoinItemModal/BCoinItemModal';
import { useBCoinItemCard } from './useBCoinItemCard';
import { BCoinItemCardProps } from './types';
import MadeInUkraineBadge from './components/MadeInUkraineBadge';
import ItemPrice from './components/ItemPrice';
import { FC, useState } from 'react';
import HeartIcon from './components/assets/HeartIcon';
import { Tooltip } from 'react-tooltip';
import { toggleBCoinRewardInWishlist } from '../../../../api/bcoin';
import Loader from '../../../../components/Loader/Loader';
import { handleError } from '../../../../api/config';

const BCoinItemCard: FC<BCoinItemCardProps> = ({
  item,
  employeeBCoinBalance,
  refreshData,
  isWishlist,
}) => {
  const { element, toggleViewItem, toggleViewStoreItem } = useBCoinItemCard();

  const [isInWishlist, setIsInWishlist] = useState<boolean>(
    !!item.inWishlist || !!isWishlist,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleWishlistToggle = async (id: number) => {
    try {
      setLoading(true);

      await toggleBCoinRewardInWishlist(id);
      refreshData?.();
      setIsInWishlist((prevIsInWishlist) => !prevIsInWishlist);

      setLoading(false);
    } catch (error) {
      handleError(error);
      setLoading(false);
    }
  };

  const progressBarWidth = item.price
    ? Math.min((employeeBCoinBalance / item.price) * 100, 100)
    : 0;

  if (!element) {
    return null;
  }

  return (
    <>
      <div
        className='bcoin-item-card'
        id={`bcoin-item-card-${item.id}`}
        onClick={toggleViewItem}
      >
        {item.madeInUkraine && <MadeInUkraineBadge />}

        <div className='item-card-img'>
          <img src={item.primaryImage} alt={item.name} />
        </div>

        <div className='item-card-title'>{item.name}</div>

        <div className='item-card-price'>
          <ItemPrice item={item} />
        </div>

        <div
          className='item-card-wishlist-btn'
          onClick={(e) => {
            e.stopPropagation();
            handleWishlistToggle(item.id);
          }}
        >
          <HeartIcon
            stroke={isInWishlist ? '#2979FF' : '#FDFDFD'}
            fill={isInWishlist ? '#2979FF' : 'none'}
          />
        </div>

        {isInWishlist && (
          <div className='item-card-progress-bar-container'>
            <div
              className='item-card-progress-bar'
              style={{ width: `${progressBarWidth}%` }}
            />
          </div>
        )}
        {item.price
          ? isInWishlist &&
            employeeBCoinBalance < item.price && (
              <Tooltip
                anchorSelect={`#bcoin-item-card-${item.id}`}
                place='bottom'
                className='card-tooltip'
                style={{ backgroundColor: '#000000DE' }}
                float={false}
              >
                <span>
                  {item.price - employeeBCoinBalance}/{item.price ?? 0}
                </span>{' '}
                b-coins left to reach
              </Tooltip>
            )
          : null}
      </div>

      {loading && <Loader />}

      {toggleViewStoreItem &&
        ReactDOM.createPortal(
          <BCoinItemModal
            item={item}
            onCancelButtonClick={toggleViewItem}
            employeeBCoinBalance={employeeBCoinBalance}
          />,
          element,
        )}
    </>
  );
};

export default BCoinItemCard;
