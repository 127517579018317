import { useCallback, useEffect, useRef, useState } from 'react';
import useMouseWheelScroll from '../../../../hooks/useMouseWheelScroll';
import { getBCoinRewardImages } from '../../../../api/bcoin';
import { BCoinItemModalProps, UseBCoinItemModalReturn } from './types';

export const useBCoinItemModal = (
  props: BCoinItemModalProps,
): UseBCoinItemModalReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [slideIndex, setSlideIndex] = useState(0);
  const [successfullIsVisible, setSuccessfullIsVisible] = useState(false);
  const [displaySuccessOpacity, setDisplaySuccessOpacity] = useState(false);

  const {
    item: { price = 0 },
    employeeBCoinBalance,
  } = props;

  const isBalanceInsufficient = employeeBCoinBalance < price;
  const isBalanceZero = employeeBCoinBalance === 0;

  const disableConfirmButton = isBalanceInsufficient || isBalanceZero;

  const handleNext = useCallback(() => {
    setSlideIndex((prevIndex) => prevIndex + 1);
  }, []);

  const handlePrev = useCallback(() => {
    setSlideIndex((prevIndex) => prevIndex - 1);
  }, []);

  const displaySuccessfull = useCallback(() => {
    setSuccessfullIsVisible(true);

    setTimeout(() => {
      setDisplaySuccessOpacity(true);
    }, 100);
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);
  const { slider } = useMouseWheelScroll(containerRef);

  const { id: itemId } = props.item;
  const characteristics = props.item.characteristics
    ?.split(/\r?\n/)
    .slice(0, -1);

  useEffect(() => {
    if (itemId) {
      const getItems = async () => {
        setIsLoading(true);

        const apiItems = await getBCoinRewardImages(itemId);

        if (apiItems instanceof Array) setImages(apiItems);

        setIsLoading(false);
      };
      getItems();
    }
  }, []);

  return {
    characteristics,
    containerRef,
    disableConfirmButton,
    displaySuccessOpacity,
    displaySuccessfull,
    handleNext,
    handlePrev,
    images,
    isLoading,
    itemId,
    setIsLoading,
    slideIndex,
    slider,
    successfullIsVisible,
  };
};
