import {
  BCoinAchievementDto,
  BCoinEmployeeDto,
  BCoinRewardDto,
  BCoinTaskCompletedDto,
  BCoinTaskDto,
  RewardCheckoutFormDTO,
} from './api-types';
import {
  ACHIEVEMENT,
  BCOIN,
  EMPLOYEES,
  IMAGES,
  REWARDS,
  TASKS,
  handleError,
  makeRequest,
} from './config';

export const getBCoinBalances = async (name?: string) => {
  const url = `${BCOIN}${EMPLOYEES}${name ? `?name=${name}` : ''}`;
  try {
    const response = await makeRequest.get<BCoinEmployeeDto[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const toggleBCoinRewardInWishlist = async (rewardId: number) => {
  const url = `${BCOIN}${REWARDS}/wishlist?rewardId=${rewardId}`;
  try {
    await makeRequest.put(url);
  } catch (e) {
    handleError(e);
  }
};

export const getEmployeeBCoinBalance = async () => {
  try {
    const { data } = await makeRequest.get<number>(
      `${BCOIN}${EMPLOYEES}/balance`,
    );
    return data ?? 0;
  } catch (e) {
    handleError(e);
    return 0;
  }
};

export const getEmployeeBCoinTasksCompleted = async () => {
  try {
    const { data } = await makeRequest.get<BCoinTaskCompletedDto[]>(
      `${BCOIN}${TASKS}/completed`,
    );
    return data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getBCoinAchievements = async () => {
  const url = `${BCOIN}${ACHIEVEMENT}`;
  try {
    const response = await makeRequest.get<BCoinAchievementDto[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getBCoinTasks = async () => {
  const url = `${BCOIN}${TASKS}`;
  try {
    const response = await makeRequest.get<BCoinTaskDto[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getBCoinRewards = async () => {
  const url = `${BCOIN}${REWARDS}`;
  try {
    const response = await makeRequest.get<BCoinRewardDto[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getBCoinRewardWishlist = async () => {
  const url = `${BCOIN}${REWARDS}/wishlist`;
  try {
    const response = await makeRequest.get<BCoinRewardDto[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getBCoinRewardImages = async (id: number) => {
  const url = `${BCOIN}${REWARDS}/${id}${IMAGES}`;
  try {
    const response = await makeRequest.get<string[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const exchangeBCoinsForReward = async (data: RewardCheckoutFormDTO) => {
  const url = `${BCOIN}${REWARDS}`;
  try {
    await makeRequest.post(url, data);
  } catch (e) {
    handleError(e);
  }
};

export const sendBCoinTaskCompletion = async ({
  id,
  status,
}: {
  id: number;
  status: BCoinTaskStatusValue;
}) => {
  const url = `${BCOIN}${TASKS}/${id}?status=${status}`;
  try {
    await makeRequest.post(url);
  } catch (e) {
    handleError(e);
  }
};

export const BCOIN_TASK_STATUS = {
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
} as const;

type BCoinTaskStatus = typeof BCOIN_TASK_STATUS;
type BCoinTaskStatusKey = keyof BCoinTaskStatus;
export type BCoinTaskStatusValue = BCoinTaskStatus[BCoinTaskStatusKey];
