import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import styles from '../../styles/bcoin.module.scss';
import BCoinHeader from '../../modules/BCoin/components/BCoinHeader/BCoinHeader';
import { bCoinTabs } from './constants';
import { useBCoinPage } from './useBCoinPage';

const BCoinPage = () => {
  const { navigate, employeeBCoinBalance } = useBCoinPage();

  return (
    <div className={styles.container}>
      <BCoinHeader employeeBCoinBalance={employeeBCoinBalance ?? 0} />
      <Outlet />
      <div className={styles.tabContainer}>
        {bCoinTabs.map((tab) => (
          <NavLink
            key={tab.id}
            to={`/b-coin/${tab.id}`}
            className={({ isActive }) =>
              isActive ? 'bcointab-active' : 'bcointab'
            }
            onClick={() => navigate(`/b-coin/${tab.id}`)}
          >
            <span>{tab.label}</span>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default BCoinPage;
