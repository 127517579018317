import { exchangeBCoinsForReward } from '../../../../../../api/bcoin';
import styles from './ConfirmationForm.module.scss';
import { ConfirmationFormProps } from './types';
import { useConfirmationForm } from './useConfirmationForm';

const ConfirmationForm = ({
  handlePrev,
  bcoinAmmount,
  itemId,
  displaySuccessfull,
  setIsLoading,
}: ConfirmationFormProps) => {
  const { handleConfirm } = useConfirmationForm({
    itemId,
    setIsLoading,
    exchangeBCoinsForReward,
    displaySuccessfull,
  });

  return (
    <div className={styles.root}>
      <h2>Confirm your order?</h2>
      <p>
        You will spend <span>{bcoinAmmount}</span> b-coins from your balance.
      </p>
      <div className={styles.buttons}>
        <button onClick={handlePrev} className={styles.cancelBtn}>
          Cancel
        </button>
        <button onClick={handleConfirm} className={styles.yesBtn}>
          Yes
        </button>
      </div>
    </div>
  );
};

export default ConfirmationForm;
