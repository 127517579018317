import { useEffect, useState } from 'react';
import RobotSitsImage from '../../../images/RobotSitsImage.png';
import BCoinBodyLeftCol from '../components/BCoinBodyLeftCol';
import { getBCoinTasks } from '../../../api/bcoin';
import Loader from '../../../components/Loader/Loader';
import HowToGetList from '../components/HowToGetList';
import { BCoinTaskDto } from '../../../api/api-types';
import classNames from 'classnames';

const BCoinHowToGet = () => {
  const [items, setItems] = useState<BCoinTaskDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const getItems = async () => {
      setIsLoading(true);
      const apiItems = await getBCoinTasks();
      setItems(apiItems ?? []);
      setIsLoading(false);
    };
    getItems();
  }, []);

  return (
    <div className='bcoin-body'>
      <BCoinBodyLeftCol
        imageAsset={RobotSitsImage}
        imageClassName={classNames('bcoin-body-left-col-img-offset', 'how-to-get-image')}
        titleClassName='bcoin-how-to-get-title'
        subtitleClassName='bcoin-how-to-get-subtitle'
        title='How to get'
        subtitle='b-coin?'
      />
      <div className='bcoin-body-right-col'>
        {items.length ? <HowToGetList items={items} /> : null}
      </div>
      {isLoading ? <Loader /> : null}
    </div>
  );
};

export default BCoinHowToGet;
