import React from 'react';
import classNames from 'classnames';

export type ButtonProps = {
  id?: string;
  styleClass?: string;
  label?: string;
  active?: boolean;
  icon?: string | React.ReactNode;
  children?: React.ReactNode;
  hasTooltip?: boolean;
} & JSX.IntrinsicElements['button'];

const Button = ({
  id = '',
  styleClass = '',
  label = '',
  active = undefined,
  icon = null,
  children,
  hasTooltip,
  ...rest
}: ButtonProps) => {
  const classes = classNames('btn', styleClass, { active });
  return (
    <button data-tooltip-id={id} className={classes} {...rest}>
      {icon && <span>{icon}</span>}
      {children ?? label}
      {hasTooltip && (
        <span className='order-tooltip'>
          Insufficient balance to place an order.
        </span>
      )}
    </button>
  );
};

export default Button;
